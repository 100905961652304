import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Button from "react-bootstrap/Button"
import logo from "../images/integrant-logo-no-text.png"
import { FaCubes } from "@react-icons/all-files/fa/FaCubes"

import "./index.scss"

const Index = ({ setTransparentNav }) => {
  useEffect(() => {
    setTransparentNav(true)
  }, [])
  return (
    <>
      <Seo title="Home" />
      <div className="contentWrapper">
        <div className="content">
          <div className="overlay">
            <div className="title">
              <img src={logo} alt="Integrant Labs Logo" />
              <h1>Integrant Labs</h1>
              <p>Building your software.</p>
            </div>
            <div className="buttons">
              <Link to={"/services"}>
                <Button variant="warning" mr="0.5rem">
                  <FaCubes className="icon-style" />
                  Our Services
                </Button>
              </Link>
              <Link to={"/labs"}>
                <Button variant="outline-warning">Enter the Lab</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
